<template>
  <v-window touchless v-model="window">
    <v-window-item :value="0">
      <v-row dense class="pa-3 pb-0">
        <v-col cols="12" class="d-flex justify-space-between pa-0">
          <div class="body-1 black--text">
            {{charge.type === 'promo' ? 'Promo Code' : 'Payment'}} <span class="xsmall grey--text">{{charge.id}}</span>
            <v-btn
              color="error"
              @click.stop="window = 1"
              v-if="charge.type==='external' && canRefund"
              text small
            >
              Delete
            </v-btn>
          </div>
          <div class="money black--text">{{charge.amount | usDollars}}</div>
        </v-col>
        <v-col cols="12" class="pa-0 body-2 grey--text" v-if="charge.type !== 'promo'">
          {{charge.paymentMethod}}{{charge.notes ? ` - ${charge.notes}` : null}}
          <v-btn
            color="color3 color3Text--text"
            x-small text
            v-if="charge.type === 'stripe' && canRefund && itemTotal(charge)"
            @click="refundClick(charge)"
          >
            refund
          </v-btn>

        </v-col>
        <v-col cols="12" class="pa-0 caption grey--text">
          {{charge.dt | unixToLocalDt}} | By:  {{charge.user}}
        </v-col>
      </v-row>
    </v-window-item>
    <v-window-item :value="1">
      <v-row dense class="pa-3 pb-0">
        <v-col cols="12">
          Are you sure?
          <v-btn color="success" class="mr-2" @click.stop="doDelete" :loading="loading">Yes</v-btn>
          <v-btn color="error" @click.stop="window=0">No</v-btn>
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>

<script>
import sum from 'lodash.sum'
export default {
  props: ['charge', 'canRefund'],
  data () {
    return {
      window: 0,
      loading: false
    }
  },
  methods: {
    itemTotal (charge) {
      return charge.amount - (charge.refunds ? sum(charge.refunds.map((r) => r.amount)) : 0)
    },
    refundClick (charge) {
      this.$emit('refund-click', charge)
    },
    doDelete () {
      this.loading = true
      this.$emit('delete-click', this.charge.id)
    }
  }
}
</script>
